exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-game-jams-js": () => import("./../../../src/pages/GameJams.js" /* webpackChunkName: "component---src-pages-game-jams-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practise-js": () => import("./../../../src/pages/Practise.js" /* webpackChunkName: "component---src-pages-practise-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/Reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/Research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

